import React from "react";
import { Navigate } from "react-router-dom";
import UserAuth from "./UserAuth";
import Cookies from "js-cookie";
import PropTypes from "prop-types";

const LoginAuth = ({ children }) => {
  const requestId = Cookies.get("requestId");
  const AuthToken = Cookies.get("authtoken");

  if (requestId && AuthToken) {
    return <Navigate replace to={"/dashboard"} />;
  } else {
    return <>{children}</>;
  }
};
LoginAuth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LoginAuth;
