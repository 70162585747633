import React from "react";
import { Navigate } from "react-router-dom";
import UserAuth from "./UserAuth";
import Cookies from "js-cookie";
import PropTypes from "prop-types";

const PrivateRoute = ({ children }) => {
  // if (!token) {
  //   return <Navigate replace to={"/login"} />;
  // } else {
  //   return <>{children}</>;
  // }

  // ====
  const token = Cookies.get("requestId");
  const audioFile = Cookies.get("audiofile")
  const bankForm = Cookies.get("bankform")
  const typeForm = Cookies.get("typeform")
  const otpCheck = Cookies.get("otpsucess");
  // const audioFile = "true";
  // const bankForm = "true";
  // const typeForm = "true";

  console.log(otpCheck);
  // const profilePicture = Cookies.get("profilepicture") === "";

  if (!token || !otpCheck) {
    return <Navigate replace to={"/login"} />;
  } else if (
    audioFile === "true" ||
    bankForm === "true" ||
    typeForm === "true"
  ) {
    return <Navigate replace to={"/dashboard"} />;
  } else {
    return <>{children}</>;
  }
};
PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrivateRoute;
