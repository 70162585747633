import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";

const UserAuth = ({ children }) => {
  const token = Cookies.get("requestId");
  const audioFile = Cookies.get("audiofile")
  const bankForm = Cookies.get("bankform")
  const typeForm = Cookies.get("typeform")
  const otpCheck = Cookies.get("otpsucess");
  // const audioFile = "true";
  // const bankForm = "true";
  // const typeForm = "true";

  console.log(otpCheck);
  // const profilePicture = Cookies.get("profilepicture") === "";

  if (!token || !otpCheck) {
    return <Navigate replace to={"/login"} />;
  } else if (
    audioFile === "false" ||
    bankForm === "false" ||
    typeForm === "false"
  ) {
    return <Navigate replace to={"/pendingprofileform"} />;
  } else {
    return <>{children}</>;
  }
};

UserAuth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserAuth;
