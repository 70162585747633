import { createSlice, configureStore } from "@reduxjs/toolkit";

const tokenSlice = createSlice({
  name: "token",
  initialState: {
    token: null,
    isAuthenticated: false,
  },
  reducers: {
    token: (state, action) => {
      state.token = action.payload.token;
      state.isAuthenticated = true;
    },
  },
});

const joinFormSlice = createSlice({
  name: "joinForm",
  initialState: {
    formData: {
      firstName: "",
      lastName: "",
      email: "",
      instagramUrl: "",
      twitterUrl: "",
      youtubeUrl: "",
      profilePhoto: null,
      images: [],
    },
  },
  reducers: {
    joinForm: (state, action) => {
      console.log(action.payload);
      state.formData = action.payload;
    },
  },
});

const profileData = createSlice({
  name: "profileData",
  initialState: {
    profileData: {},
  },
  reducers: {
    OtpProfileData: (state, action) => {
      console.log(action.payload, "otpData");
      state.profileData = action.payload;
    },
  },
});

const store = configureStore({
  reducer: {
    token: tokenSlice.reducer,
    joinForm: joinFormSlice.reducer,
    OtpProfileData: profileData.reducer,
  },
});
export const { token } = tokenSlice.actions;
export const { joinForm } = joinFormSlice.actions;
export const { OtpProfileData } = profileData.actions;
export default store;

// =================

// import { configureStore, createReducer } from "@reduxjs/toolkit"

// const initialState ={
//   sidebarShow: true,
//   sidebarUnfoldable : false
// }

// const changeState = createReducer(initialState , (builder)=>{
//    builder
//    .addCase("set",(state)=>{
//     state.sidebarShow = false;
//     state.sidebarUnfoldable = true
//    })
// })

// export const changeStateFunc = (type) => async (dispatch)=>{

//   dispatch({type : type})

// }

// const store = configureStore({
//   reducer :{
//     changeState : changeState
//   }
// })

// export default store
