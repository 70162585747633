import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";

const OtpAuth = ({ children }) => {
  const AuthToken = Cookies.get("authtoken");
  const requestId = Cookies.get("requestId");

  if (AuthToken) {
    return <Navigate replace to={"/dashboard"} />;
  } else if (!requestId) {
    return <Navigate replace to={"/login"} />;
  } else {
    return <>{children}</>;
  }
};

OtpAuth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default OtpAuth;
